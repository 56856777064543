export const getEndCount = (page, perPage, count) => {
  if (page == 1 && count === perPage) {
    return perPage
  }

  if (count === 0) {
    return 0
  }

  return count + (page - 1) * perPage
}

export function shouldResetPagination(
  previous,
  newSearch,
  newOrderBy,
  newOrderDir,
  newPage
) {
  return (
    newSearch !== previous.search ||
    newOrderBy !== previous.orderBy ||
    newOrderDir !== previous.orderDir ||
    newPage === null
  )
}

// TODO: see if we can move this logic to the Paginator component without breaking all the places
// where the Paginator is being used
// Use this when you need to update attributes in the reducer that requires the pagination to be reset
export const resetPagination = (
  array,
  nextPage,
  nextPageProps = 'next_page'
) => {
  const size = array.length
  return {
    [nextPageProps]: nextPage,
    endCount: size,
    startCount: size === 0 ? 0 : 1,
    currentPage: nextPage ? nextPage - 1 : 1
  }
}

// This function can calculate all the pagination details to be used inside a reducer
export const calculatePaginationInfo = (
  array,
  originalNextPage,
  goingForward,
  oldData,
  nextPageProps = 'next_page',
  reset = false
) => {
  if (reset) {
    return resetPagination(array, null, nextPageProps)
  }

  const size = array.length
  const { perPage, endCount, nextPage, startCount } = oldData
  const previousStartingPoint = startCount - perPage
  let nextStartPoint = previousStartingPoint < 0 ? 1 : previousStartingPoint

  if (nextStartPoint === 1 && size == 0) {
    nextStartPoint = 0
  }

  return {
    [nextPageProps]: originalNextPage,
    endCount: goingForward ? endCount + size : startCount - 1,
    startCount: goingForward ? endCount + 1 : nextStartPoint,
    currentPage: goingForward ? nextPage : nextPage - 1
  }
}
