export const getMixpanelId = () => {
  if (
    window.mixpanel &&
    typeof window.mixpanel.get_distinct_id === 'function'
  ) {
    return window.mixpanel.get_distinct_id()
  }
}

export const getMixpanelProperty = prop => {
  if (window.mixpanel && typeof window.mixpanel.get_property === 'function') {
    return window.mixpanel.get_property(prop)
  }
}
