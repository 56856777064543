import { MARKETPLACES } from '../constants/countries'

export const getLanguageCode = countryCode => {
  const defaultLanguageCode = 'en-US'
  if (countryCode) {
    const marketplace = MARKETPLACES[countryCode.toLowerCase()]
    if (marketplace) {
      return marketplace.languageCode || defaultLanguageCode
    }
  }
  return defaultLanguageCode
}

export const removeNonAmazonMarketplaces = amazonSellerAccount => {
  if (amazonSellerAccount && Array.isArray(amazonSellerAccount.countries)) {
    amazonSellerAccount.countries = amazonSellerAccount.countries.filter(
      country => country.name !== 'Non-Amazon' && country.country_code
    )
  }
  return amazonSellerAccount
}
