import moment from 'moment'

import { PPC_SYNC_RETURN_URL } from 'constants/routes'
import { LOCAL_STORAGE_KEYS } from 'constants/account'

import { randomString } from 'helpers/strings'
import { setLocalData } from 'helpers/storage'

const ADVERTISING_API_CLIENT_ID =
  process.env.REACT_APP_AMAZON_ADVERTISING_API_CLIENT_ID

export const loadAmazonLoginSdk = () => {
  let amzRootDiv = document.getElementById('amazon-root')
  let scriptTag = document.getElementById('amazon-login-sdk')

  if (amzRootDiv != null && scriptTag != null) {
    return
  }

  const scriptUrl =
    process.env.NODE_ENV === 'test'
      ? ''
      : 'https://api-cdn.amazon.com/sdk/login1.js'

  amzRootDiv = document.createElement('div')
  amzRootDiv.id = 'amazon-root'

  window.onAmazonLoginReady = function() {
    amazon.Login.setClientId(ADVERTISING_API_CLIENT_ID)
  }

  scriptTag = document.createElement('script')
  scriptTag.type = 'text/javascript'
  scriptTag.async = true
  scriptTag.id = 'amazon-login-sdk'
  scriptTag.src = scriptUrl

  amzRootDiv.appendChild(scriptTag)
  document.body.appendChild(amzRootDiv)
}

export const loginWithAmazon = (
  sellerAccountId,
  withMwsSync,
  stringGeneratorFunc = randomString
) => {
  const expiresIn = moment(Date.now())
    .add(1, 'h')
    .toString()
  const stateStr = stringGeneratorFunc(20)
  const originPath = window.location.hash.substr(1)

  const state = {
    state: stateStr,
    expires: expiresIn,
    sellerAccountId,
    originPath,
    withMwsSync
  }

  setLocalData(
    LOCAL_STORAGE_KEYS.PPC_SYNC_REDIRECT_STATE,
    JSON.stringify(state)
  )

  const options = {
    scope: 'cpc_advertising:campaign_management',
    response_type: 'code',
    state: stateStr,
    popup: false
  }
  const redirectUrl = `${window.location.origin}${PPC_SYNC_RETURN_URL}`

  amazon.Login.authorize(options, redirectUrl)
}
