import {
  WRITE_PERMISSION,
  TIMEFRAME_LIMIT_TYPE,
  QUANTITY_LIMIT_TYPE,
  MONTHLY_SEARCHES_SCOPE,
  LISTINGS_SCOPE,
  PRODUCT_TRACKS_SCOPE,
  SUPPLIER_TRACKS_SCOPE,
  SUPPLIER_GROUPS_SCOPE,
  RANK_TRACKER_KEYWORDS_SCOPE,
  GATED_ROUTES,
  NO_ACCESS_ROUTES,
  GATED_FEATURES
} from 'constants/account/permissions'

export const hasAccess = (permissions, permissionToCheck) => {
  // The backend uses 'write', 'read', or 'none' values for permissions.
  // Currently the access is all or nothing so both 'read' and 'none' will
  // return false.
  return !!permissions && permissions[permissionToCheck] === WRITE_PERMISSION
}

const isMatchingRoute = (pathname, route) => new RegExp(route).test(pathname)

export const isRouteGated = pathname => {
  return NO_ACCESS_ROUTES.some(route => isMatchingRoute(pathname, route))
}

export const isFeatureGated = pathname => {
  return GATED_FEATURES.some(route => isMatchingRoute(pathname, route))
}

export const isUserGated = (featureLimits, pathname) => {
  const currentRoute = Object.keys(GATED_ROUTES).find(route =>
    isMatchingRoute(pathname, route)
  )

  if (!currentRoute) {
    return false
  }

  const featureLimitKey = GATED_ROUTES[currentRoute]

  return (
    featureLimits &&
    (featureLimits[featureLimitKey]?.featureLimit == null ||
      featureLimits[featureLimitKey]?.featureLimit === 0)
  )
}

const getTranslatedScopeUnlockingMessage = (scope, t) => {
  switch (scope) {
    case MONTHLY_SEARCHES_SCOPE:
      return t(
        'account:FeatureLimitModal.monthlySearches',
        'Unlock more monthly searches.'
      )
    case LISTINGS_SCOPE:
      return t('account:FeatureLimitModal.listings', 'Unlock more listings.')
    case PRODUCT_TRACKS_SCOPE:
      return t(
        'account:FeatureLimitModal.productTracks',
        'Unlock to track more products.'
      )
    case SUPPLIER_TRACKS_SCOPE:
      return t(
        'account:FeatureLimitModal.supplierTracks',
        'Unlock to track more suppliers.'
      )
    case SUPPLIER_GROUPS_SCOPE:
      return t(
        'account:FeatureLimitModal.supplierGroups',
        'Unlock to create more groups.'
      )
    case RANK_TRACKER_KEYWORDS_SCOPE:
      return t(
        'account:FeatureLimitModal.rankTrackerKeywords',
        'Unlock more keywords.'
      )
    default:
      return t('account:FeatureLimitModal.searches', 'Unlock more searches.')
  }
}

export const getFeatureLimitModalCopy = (limitType, t, scope) => {
  switch (limitType) {
    case TIMEFRAME_LIMIT_TYPE:
      return {
        header: t(
          'account:FeatureLimitModal.timeframeHeader',
          'Want to see more?'
        ),
        body: t(
          'account:FeatureLimitModal.timeframeBody',
          'Upgrade to extend graph data history.'
        )
      }
    case QUANTITY_LIMIT_TYPE:
      return {
        header: t(
          'account:FeatureLimitModal.quantityHeader',
          'Time for an upgrade?'
        ),
        body: getTranslatedScopeUnlockingMessage(scope, t)
      }
    default:
      return {
        header: t(
          'account:FeatureLimitModal.accessHeader',
          'Upgrade to get full access!'
        ),
        body: t(
          'account:FeatureLimitModal.accessBody',
          'Our tools work better together to boost your productivity.'
        )
      }
  }
}
